import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import howItWork from "../../assets/images/how-its-work.png"

const WorkingProcess = () => {
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="star" />
            OUR WORKING PROCESS
          </span>
          <h2>
            Greater control over different projects through Agile Methodology.
          </h2>
          <p>
            Our major role is customer satisfaction by rapid delivery of
            softwares.
          </p>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              {/* <span className="sub-title">
                <img src={starIcon} alt="banner" />
                People Love Us
              </span>
              <h2>Why Should you choose iSkillers?</h2> */}

              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>Requirements</h3>
                  <p>
                    Our Project Management team collect requirement and analyse
                    them for preparing road map, sprint and initiate plan.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>Planning</h3>
                  <p>
                  iHunar Project Management team breakdown the all
                    requirements and set priorities and in the last iterate them
                    for scheduling.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>Design</h3>
                  <p>
                    Share architecture spike analysis, design and implement the
                    prototype.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">4</div>
                  <h3>Development</h3>
                  <p>
                  iHunar Project Management team breakdown the all
                    requirements and set priorities and in the last iterate them
                    for scheduling.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">5</div>
                  <h3>Testing</h3>
                  <p>
                    Team will work on testing, identify defects, and resolve
                    those bugs.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">6</div>
                  <h3>Release & Review</h3>
                  <p>
                    Demonstrate the user's stories, implemented and done to the
                    product owner.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkingProcess
