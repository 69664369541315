import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import OurSolutions from "../components/Index/OurSolutions"
import OurServices from "../components/Index/OurServices"
import OurFeatures from "../components/Index/OurFeatures"
import TeamMember from "../components/Index/TeamMember"
import Pricing from "../components/Index/Pricing"
import Testimonials from "../components/Index/Testimonials"
import Partner from "../components/Index/Partner"
import ProjectStartArea from "../components/Index/ProjectStartArea"
import OurBlog from "../components/Index/OurBlog";
import RelatedCourses from '../components/CoursesDetails/RelatedCourses';
import WhyChooseUs from '../components/AboutUs/WhyChooseUs';
import WorkingProcess from '../components/WorkingProcess/index';
import Technologies from '../components/Index/Technlogies'

const Home = () => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      {/* <OurSolutions /> */}
      <WhyChooseUs />
      <RelatedCourses />
      <Technologies />
      <ProjectStartArea />
      <WorkingProcess />
      <Footer />
      {/* <OurServices /> */}
      {/* <OurFeatures /> */}
      {/* <TeamMember /> */}
      {/* <Pricing /> */}
      {/* <Testimonials /> */}
      {/* <Partner /> */}
      {/* <OurBlog /> */}
    </Layout>
  )
}

export default Home
