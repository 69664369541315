import React from "react"
import starIcon from "../../assets/images/star-icon.png"
import howItWork from "../../assets/images/how-its-work.png"

const WhyChooseUs = () => {
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img src={starIcon} alt="banner" />
                People Love Us
              </span>
              <h2>Why Should you choose iHunar?</h2>
              <p>
                We have been working since 2020, as an IT services provider
                company, we are developing software applications and mobile
                apps, designing websites, branding businesses for clients all
                over the world.
              </p>
              {/* <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Data for All Your People</h3>
                                    <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>A New Breed of AI</h3>
                                    <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Analytics Business</h3>
                                    <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                                </div>
                            </div> */}
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={howItWork} alt="banner" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
