import React from "react"
import ReactWOW from "react-wow"
import { Link } from "gatsby"
import bannerImg from "../../assets/images/ISKILLRES-01-20211012122521.png"

const Banner = () => {
  return (
    <div className="it-services-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1>iHunar - IT Company</h1>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <p>
                  IT services provider company managed by highly experienced
                  information technology professionals.
                </p>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInRight">
                <div className="btn-box">
                  <Link to="/contact" className="default-btn">
                     Get in Touch 
                  </Link>
                </div>
              </ReactWOW>
            </div> 
          </div>

          <div className="col-lg-6 col-md-12">
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="main-banner-image">
                <img src={bannerImg} alt="banner" />
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
)
}
export default Banner
