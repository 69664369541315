import React, { useState } from "react"
import { Link } from "gatsby"
import starIcon from "../../assets/images/star-icon.png"
import IOSIcon from "../../assets/images/Technologies/ios.png"
import AndroidIcon from "../../assets/images/Technologies/android.png"
import ReactNativeIcon from "../../assets/images/Technologies/react.png"
import MongoDbIcon from "../../assets/images/Technologies/mongodb.png"
import gatsbyIcon from '../../assets/images/Technologies/gaytsby.png'
import typescriptIcon from '../../assets/images/Technologies/typescript.png'
import htmlIcon from '../../assets/images/Technologies/html.png'
import cssIcon from '../../assets/images/Technologies/css.png'
import nodejsIcon from '../../assets/images/Technologies/nodejs.png'
import cmsIcon from '../../assets/images/Technologies/cms.png'
import awsIcon from '../../assets/images/Technologies/aws.png'
import firebaseIcon from '../../assets/images/Technologies/firebase.png'
import DynamoDBIcon from '../../assets/images/Technologies/DynamoDB.png'
import expoIcon from '../../assets/images/Technologies/expo.png'


const Technologies = () => {
  const [technolgiesTypes, setTechnolgiesTypes] = useState([
    {
      technologiesType: "MOBILE",
      technologies: [
        {
          technologyName: "iOS",
          technologyIcon: IOSIcon,
          technologyTYpe: "MOBILE",
          width:40,
          height: 50
        },
        {
          technologyName: "Android",
          technologyIcon: AndroidIcon,
          technologyTYpe: "MOBILE",
          width:50,
          height: 50
        },
        {
          technologyName: "React Native",
          technologyIcon: ReactNativeIcon,
          technologyTYpe: "MOBILE",
          width:50,
          height: 50
        },
        {
          technologyName: "Expo",
          technologyIcon: expoIcon,
          technologyTYpe: "MOBILE",
          width:50,
          height: 50
        },
      ],
    },
    {
      technologiesType: "FRONT END",
      technologies: [
        {
          technologyName: "React Js",
          technologyIcon: ReactNativeIcon,
          width:50,
          height: 50
        },
        {
          technologyName: "Gatsby Js",
          technologyIcon: gatsbyIcon,
          width:50,
          height: 50
        },
        {
          technologyName: "TypeScript",
          technologyIcon: typescriptIcon,
          width:50,
          height: 50
        },
        {
          technologyName: "HTML5",
          technologyIcon: htmlIcon,
          width:50,
          height: 50
        },
        {
          technologyName: "CSS3",
          technologyIcon: cssIcon,
          width:50,
          height: 50
        },
      ],
    },
    {
      technologiesType: "DATABASE",
      technologies: [
        {
          technologyName: "Mongo DB",
          technologyIcon: MongoDbIcon,
          technologyTYpe: "MOBILE",
          width:30,
          height: 50
        },
        {
          technologyName: "Firebase",
          technologyIcon: firebaseIcon,
          technologyTYpe: "MOBILE",
          width:50,
          height: 50
        },
        {
          technologyName: "DynamoDB",
          technologyIcon: DynamoDBIcon,
          technologyTYpe: "MOBILE",
          width:50,
          height: 50
        },
      ],
    },
    {
      technologiesType: "BACKEND",
      technologies: [
        {
          technologyName: "Node .JS",
          technologyIcon: nodejsIcon,
          width:50,
          height: 50
        },

      ],
    },
    {
      technologiesType: "CMS",
      technologies: [
        {
          technologyName: "WordPress",
          technologyIcon: cmsIcon,
          technologyTYpe: "MOBILE",
          width:50,
          height: 50
        },

      ],
    },
    {
      technologiesType: "DEVOPS",
      technologies: [
        {
          technologyName: "AWS",
          technologyIcon: awsIcon,
          width:80,
          height: 50
        },

      ],
    },
  ])
  const [selectedTechnologyType, setSelectedTechnologyType] = useState(0)
  const [technologies, setTechnologies] = useState()

  return (
    <section className="projects-area bg-color pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="project" /> Technologies
          </span>
          <h2>Technologies we work with</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            marginBottom: 70,
          }}
        >
          {technolgiesTypes.map((val, i) => {
            return (
              <div
                className={
                  selectedTechnologyType === i
                    ? "technolgiesType2"
                    : "technolgiesType1"
                }
                onClick={() => setSelectedTechnologyType(i)}
              >
                <p
                  style={
                    selectedTechnologyType === i
                      ? { color: "#fff", fontSize: 14 }
                      : { color: "#212529", fontSize: 14 }
                  }
                >
                  {val.technologiesType}
                </p>
              </div>
            )
          })}
        </div>

        <div className="row" style={{ justifyContent: "center" }}>
          {technolgiesTypes[selectedTechnologyType].technologies.map(
            (val, i) => {
              return (
                <div className="col-lg-3 col-md-6 col-xs-6">
                  <div className="single-projects-box">
                    <img
                      src={val.technologyIcon}
                      alt="project"
                      style={{ width: val.width, height: val.height }}
                    />

                    <div className="content">
                      <p style={{ color: "#212529" }}>{val.technologyName}</p>
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
    </section>
  )
}

export default Technologies
